<template>
  <!--eslint-disable-->
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form class="form customer-form" id="customer_create" @submit.prevent="handleSubmit(saveCustomer)">
      <div class="row mb-3">
        <div class="col-md-4">
          <div class="card card-custom gutter-b example example-compact border border-success mb-3">
            <div class="card-header bg-success">
              <h3 class="card-title  text-white">Thông tin cơ bản</h3>
            </div>
            <!--begin::Form-->
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">Số điện thoại <span class="text-danger">*</span></label>
                    <ValidationProvider vid="phone" name="Số điện thoại"
                                        :rules="{ required: true,regex: /^(0[3|5|7|8|9])+([0-9]{8})$/ }"
                                        v-slot="{ errors,classes }">
                      <input type="number" :class="classes" class="form-control"
                             v-model="customer.phone"
                             placeholder="Số điện thoại"
                             :disabled="check_edit"
                      >
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-group">
                    <label class="form-label">Họ tên <span class="text-danger">*</span></label>
                    <ValidationProvider vid="name" name="Họ tên" rules="required"
                                        v-slot="{ errors,classes }">
                      <input type="text" class="form-control" placeholder="Họ tên"
                             v-model="customer.name" :class="classes">
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-group">
                    <label class="form-label">Email <span class="text-danger">*</span></label>
                    <ValidationProvider vid="email" name="Email" rules="required|email"
                                        v-slot="{ errors,classes }">
                      <input type="email" class="form-control" placeholder="Email"
                             v-model="customer.email"
                             :class="classes"
                             :disabled="check_edit"
                      >
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">Facebook<span class="text-danger"></span></label>
                    <input type="text" class="form-control" placeholder="Facebook" v-model="customer.link_fb">
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-group">
                    <label class="form-label">Ngày sinh <span class="text-danger"></span></label>
                    <el-date-picker value-format="yyyy-MM-dd"
                                    class="customer-birthday"
                                    v-model="customer.ngay_sinh"
                                    placeholder="Ngày sinh"
                    >
                    </el-date-picker>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">Quê quán<span class="text-danger"></span></label>
                    <input type="text" class="form-control" placeholder="Quê quán" v-model="customer.que_quan">
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-group">
                    <label class="form-label">Địa chỉ<span class="text-danger"></span></label>
                    <input type="text" class="form-control" placeholder="Địa chỉ" v-model="customer.address">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">Trường học<span class="text-danger"></span></label>
                    <input type="text" class="form-control" placeholder="Trường học" v-model="customer.co_quan">
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-group">
                    <label class="form-label">Ngành học<span class="text-danger"></span></label>
                    <input type="text" class="form-control" placeholder="Ngành học" v-model="customer.nganh">
                  </div>
                </div>
              </div>
            </div>
            <!--end::Form-->
          </div>
          <div class="card card-custom gutter-b example example-compact border border-warning">
            <div class="card-header bg-warning">
              <h3 class="card-title text-white">Thông tin phụ huynh</h3>
            </div>
            <!--begin::Form-->
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <div class="form-group">
                    <label class="form-label">Họ tên<span class="text-danger"></span></label>
                    <input type="text" class="form-control" v-model="customer.bo_me" placeholder="Họ tên">
                  </div>
                </div>
                <div class="col-md-12 mb-3">
                  <div class="form-group">
                    <label class="form-label">Số điện thoại</label>
                    <input type="number" class="form-control" v-model="customer.sdt_phu_huynh"
                           placeholder="Số điện thoại">
                  </div>
                </div>
                <div class="col-md-12 mb-3">
                  <div class="form-group">
                    <label class="form-label">Quê quán<span class="text-danger"></span></label>
                    <input type="text" class="form-control" placeholder="Quê quán"
                           v-model="customer.que_quan_phu_huynh">
                  </div>
                </div>
                <div class="col-md-12 mb-3">
                  <div class="form-group">
                    <label class="form-label">Nghề nghiệp<span class="text-danger"></span></label>
                    <input type="text" class="form-control" placeholder="Nghề nghiệp"
                           v-model="customer.nghe_nghiep_phu_huynh">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="form-label">Ý kiến<span class="text-danger"></span></label>
                    <el-select v-model="customer.y_kien_phu_huynh" clearable class="w-100" placeholder="Chọn ý kiến">
                      <el-option
                              v-for="item in yKien"
                              :key="item.id"
                              :label="item.value"
                              :value="item.id">
                        <span style="float: left">{{ item.value }}</span>
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Form-->
          </div>
        </div>
        <div class="col-md-4">
          <div class="card card-custom gutter-b example example-compact border border-primary">
            <div class="card-header bg-primary">
              <h3 class="card-title text-white">Thông tin khách hàng tiềm năng
              </h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 mb-3">
                  <div class="form-group">
                    <label class="form-label">Nguồn<span class="text-danger"></span></label>
                    <el-select v-if="!check_re_register" :disabled="customer.nguon == 'Tái đăng ký'" v-model="customer.nguon" clearable class="w-100" placeholder="Chọn ý kiến">
                      <el-option
                              v-for="item in nguon"
                              :key="item.id"
                              :label="item.value"
                              :value="item.id">
                        <span style="float: left">{{ item.value }}</span>
                      </el-option>
                    </el-select>
                    <el-input v-else disabled value="Tái đăng ký"></el-input>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">Mối quan hệ<span class="text-danger"></span></label>
                    <input type="text" class="form-control" placeholder="Mối quan hệ" v-model="customer.mqh">
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-group mb-1">
                    <label class="form-label" for="muc_tieu">Mục tiêu</label>
                    <textarea class="form-control" id="muc_tieu" rows="3" v-model="customer.muc_tieu"
                              placeholder="Mục tiêu"></textarea>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-1">
                    <label for="trinh_do" class="form-label">Trình độ</label>
                    <textarea class="form-control" id="trinh_do" rows="3" v-model="customer.trinh_do"
                              placeholder="Trình độ"></textarea>
                  </div>
                </div>
                <div class="col-md-12 mb-3">
                  <div class="form-group mb-1">
                    <label class="form-label">Phím nóng</label>
                    <textarea class="form-control" rows="3" placeholder="Phím nóng"
                              v-model="customer.phim_nong"></textarea>
                  </div>
                </div>
                <div class="col-md-12 mb-3">
                  <div class="form-group mb-1">
                    <label class="form-label">Lịch sử tìm kiếm</label>
                    <textarea class="form-control" rows="3" v-model="customer.lich_su_tim_kiem"
                              placeholder="Khách hàng đã từng đi học hoặc tìm hiểu ở đâu?"></textarea>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-group">
                    <label class="form-label">Tài chính </label>
                    <money v-bind="money" class="form-control" v-model="customer.dieu_kien_tai_chinh"></money>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">Người ra quyết định</label>
                    <el-select v-model="customer.nguoi_ra_quyet_dinh_hoc_tap" clearable class="w-100"
                               placeholder="Người ra quyết định">
                      <el-option
                              v-for="item in nguoi_ra_quyet_dinh"
                              :key="item.id"
                              :label="item.value"
                              :value="item.id">
                        <span style="float: left">{{ item.value }}</span>
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-group">
                    <label class="form-label">Quỹ thời gian</label>
                    <input type="text" class="form-control" placeholder="Quỹ thời gian"
                           v-model="customer.quy_thoi_gian">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">Khoảng cách</label>
                    <input type="text" class="form-control" placeholder="(Km)" v-model="customer.khoang_cach">
                  </div>
                </div>
                <div class="col-md-12 mb-3">
                  <div class="form-group mb-1">
                    <label class="form-label">Rào cản</label>
                    <textarea class="form-control" rows="3" placeholder="Rào cản" v-model="customer.rao_can"></textarea>
                  </div>
                </div>
                <div class="col-md-12 mb-3">
                  <div class="form-group mb-1">
                    <label class="form-label">Giải pháp mời hẹn</label>
                    <textarea class="form-control" rows="3" v-model="customer.giai_phap_moi_hen"
                              placeholder="Giải pháp mời hẹn của cấp trên"></textarea>
                  </div>
                </div>
                <div class="col-md-12 mb-3">
                  <div class="form-group mb-1">
                    <label class="form-label">Ghi chú</label>
                    <textarea class="form-control" rows="3" placeholder="Ghi chú" v-model="customer.note"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card card-custom gutter-b example example-compact border border-info mb-3">
            <div class="card-header bg-info">
              <h3 class="card-title text-white">Kết quả tư vấn</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <div class="form-group">
                    <label class="form-label">Kết quả</label>
                    <input v-if="customer.ket_qua_tu_van == '1'" disabled type="text" class="form-control is-valid"
                           placeholder="Họ tên" value="Khách hàng đã đăng ký">
                    <input v-else-if="customer.ket_qua_tu_van == '0'" disabled type="text"
                           class="form-control is-invalid" placeholder="Họ tên" value="Khách hàng không đăng ký">
                    <input v-else disabled type="text" class="form-control is-invalid" placeholder="Họ tên"
                           value="Khách hàng chưa được tư vấn">
                  </div>
                </div>
                <div class="col-md-12 mb-3" v-if="customer.ket_qua_tu_van == '1'">
                  <div class="form-group">
                    <label class="form-label">Khóa học muốn đăng ký</label>
                    <input disabled type="text" class="form-control" placeholder="Lý do"
                           v-model="customer.khoa_hoc_muon_dang_ky">
                  </div>
                </div>
                <div class="col-md-12 mb-3">
                  <div class="form-group">
                    <label class="form-label">Lý do</label>
                    <input disabled type="text" class="form-control" placeholder="Lý do"
                           v-model="customer.nguyen_nhan_khong_dang_ky">
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-group">
                    <label class="form-label">Tư vấn viên</label>
                    <input disabled type="text" class="form-control" placeholder="Tư vấn viên"
                           v-model="customer.tu_van_vien">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">SĐT tư vấn viên</label>
                    <input disabled type="text" class="form-control" placeholder="SĐT tư vấn viên"
                           v-model="customer.sdt_tvv">
                  </div>
                </div>
                <div class="col-md-12 mb-3">
                  <div class="form-group">
                    <label class="form-label">Điểm test</label>
                    <input disabled type="text" class="form-control" placeholder="Điểm test"
                           v-model="customer.diem_test">
                  </div>
                </div>
                <div class="col-md-12 mb-3">
                  <div class="form-group">
                    <label class="form-label">Nhận xét</label>
                    <input disabled type="text" class="form-control" placeholder="Nhận xét"
                           v-model="customer.nhan_xet_dau_vao">
                  </div>
                </div>
                <div class="col-md-12 mb-3">
                  <div class="form-group">
                    <label class="form-label">Phím nóng bổ sung</label>
                    <textarea class="form-control" rows="3" disabled v-model="customer.phim_nong_bo_sung"></textarea>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-group">
                    <label class="form-label">Tài chính bổ sung {{typeof customer.dieu_kien_tai_chinh_bo_sung}}</label>
                    <money  v-bind="money" class="form-control"
                           v-model="customer.dieu_kien_tai_chinh_bo_sung"></money>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">Người ra quyết định</label>
                    <el-select v-model="customer.nguoi_ra_quyet_dinh_bo_sung" clearable class="w-100"
                               placeholder="Người ra quyết định" disabled>
                      <el-option
                              v-for="item in nguoi_ra_quyet_dinh"
                              :key="item.id"
                              :label="item.value"
                              :value="item.id">
                        <span style="float: left">{{ item.value }}</span>
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="form-label">Vấn đề khách hàng sau tư vấn
                      <span class="text-danger"></span></label>
                    <textarea class="form-control" rows="3" disabled
                              v-model="customer.khach_hang_van_dang_con_van_de"
                    ></textarea>
                  </div>
                </div>
              </div>
              <hr style="margin-top: 20px;">
              <div class="row">
                <div class="col-md-12">
                  <h4 class="form-group form-label">DANH SÁCH HỢP ĐỒNG</h4>
                </div>
              </div>
              <div v-if="customer && customer?.list_contract?.length">
                <div class="row" v-for="item in customer.list_contract" style="margin-top: 10px;">
                  <div class="col-md-12">
                    <a style="font-size: 14px; color: #7453a6; text-decoration: underline !important; cursor: pointer; margin-top: 15px;" @click="handleViewContract(item)" >
                    Hợp đồng {{ customer.user.name }} - {{ item.created_at_format }}</a>
                  </div>
                </div>
              </div>
              <div v-else style="font-style: italic; margin-top: 5px;">
                <span>Chưa có hợp đồng!</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row save-customer">
        <button class="btn btn-primary float-right mt-3 ml-3" type="submit">
          <i v-if="check_loading" class="el-icon-loading"></i>
          Lưu
        </button>
        <button type="button" v-if="check_edit && checkDestroy" class="btn btn-danger float-right mt-3 ml-3"
                @click="destroyCustomer()">
          <i v-if="check_loading_destroy" class="el-icon-loading"></i>
          Hủy data
        </button>
      </div>
    </form>
    <el-dialog :title="dialog.title" class="responsive" :visible.sync="dialogFormVisible">
      <el-row :gutter="10" class="pr-2 pl-2">
        <contract-form
                v-if="dialogFormVisible"
                :customer-id="customer.id"
                :center-id="contract.center_id"
                :branch-id="contract.branch_id"
                :contract="contract"
                :check-edit="checkEdit"
                :check-view="checkView"
                @close="closeDialog">
        </contract-form>
      </el-row>
    </el-dialog>
    <el-dialog @closed="closeAndClearInfor" title="Thông báo" :visible.sync="dialogCompleteDebtOr30days" width="30%">
      <div style="word-break: break-word;">
        <p v-if="(isReRegister.completeDeblt && isReRegister.contract30days) || (isReRegister.completeDeblt && !isReRegister.contract30days)">Khách hàng này thuộc nguồn tái đăng ký nhưng chưa thể tạo do chưa hoàn thành hết công nợ của hợp đồng cũ.</p>
        <p v-else>Khách hàng này thuộc nguồn tái đăng ký nhưng chưa thể tạo do chưa đủ thời hạn tối thiểu để tái đăng ký: 03 tháng kể từ khi lên hợp đồng.</p>
        
      </div>
        <div class="d-flex justify-content-end">
          <el-button @click="closeAndClearInfor">Đã hiểu</el-button>
        </div>
    </el-dialog>
  </ValidationObserver>
</template>
<script>
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import {Money} from 'v-money'
    import {
        CUSTOMER_CREATE, CUSTOMER_DESTROY,
        CUSTOMER_FIND,
        CUSTOMER_UPDATE,
        CUSTOMER_FIND_RE_REGISTER
    } from "../../../core/services/store/customer/customer.module";
    import {mapGetters, mapState} from "vuex";
    import ContractForm from "@/views/pages/customer/contract-form";
    const _ = require('lodash');
    export default {
        components: {
            Money,
            ContractForm
        },
        name: "CustomerForm",
        data() {
            return {
                customer: {
                    list_contract: [],
                    ngay_sinh:''
                },
                is_show_room: false,
                check_loading: false,
                check_loading_destroy: false,
                check_edit: false,
                trangThaiTuVan: [
                    {
                        id: '',
                        value: "--Chọn--"
                    },
                    {
                        id: 0,
                        value: "Khách hàng không đăng ký"
                    },
                    {
                        id: 1,
                        value: "Khách hàng đã đăng ký"
                    },

                ],
                centers: [],
                yKien: [
                    {id: '', value: "(Chọn ý kiến)"},
                    {id: 'Chưa hỏi', value: 'Chưa hỏi'},
                    {id: 'Đã hỏi - Không đồng ý', value: 'Đã hỏi - Không đồng ý'},
                    {id: 'Đã hỏi - Đồng ý', value: 'Đã hỏi - Đồng ý'}
                ],
                nguon: [
                    {id: '', value: "--Chọn--"},
                    {id: 'Online Thân', value: 'Online Thân'},
                    {id: 'Online Gần', value: 'Online Gần'},
                    {id: 'Online Xa', value: 'Online Xa'},
                    {id: 'Truyền thông', value: 'Truyền thông'},
                    // {id: 'Tái đăng ký', value: 'Tái đăng ký'},
                    {id: 'Nhân trước đăng ký', value: 'Nhân trước đăng ký'},
                    {id: 'Nhân sau đăng ký', value: 'Nhân sau đăng ký'},
                ],
                nguoi_ra_quyet_dinh: [
                    {id: '', value: "--Chọn--"},
                    {id: 'Phụ huynh', value: "Phụ huynh"},
                    {id: 'Bạn bè', value: "Bạn bè"},
                    {id: 'Bản thân', value: "Bản thân"},
                ],
                trangThaiHen: [
                    {id: 0, value: "Chưa có lịch hẹn"},
                    {id: 1, value: "Có lịch hẹn"},
                    {id: 2, value: "Delay hẹn"},
                    {id: 3, value: "Hủy hẹn"},
                    {id: 4, value: "Hủy data"},
                ],

                // Cache
                cacheLevel: [],
                cacheNguon: [],
                cacheYkien: [],
                cacheTrangThaiHen: [],
                cacheTrangThaiTuVan: [],
                cacheTrungTamHen: [],
                cacheNguoiRaQuyetDinh: [],
                money: {
                    decimal: ',',
                    thousands: ',',
                    prefix: '',
                    suffix: ' VNĐ',
                    precision: 0,
                    masked: false,
                },
                checkDestroy: false,
                dialogFormVisible: false,
                dialog: {
                    title: ''
                },
                contract: null,
                checkEdit: false,
                checkView: false,
                check_re_register : false,
                check_clear : false,
                dialogCompleteDebtOr30days : false,
                isReRegister : {
                  completeDeblt : false,
                  contract30days : false
                }
            }
        },
        watch: {
            "customer.phone": function (val) {
              if(this.check_edit == false) {
                if(val.length >= 10) {
                    this.searchPhone();
                } else {
                  if(this.check_clear && this.check_re_register) {
                    this.clearReRegister();
                  }
                }
              }
            }
        },
        async mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Khách hàng", route: "customer-list"},
                {title: "Tạo khách hàng", icon: 'fas fa-users'}
            ]);
            if (this.errors) {
                this.$refs.form.setErrors(this.errors);
            }
            if (this.$route.params.id !== undefined) {
                this.check_edit = true;
                this.fetchCustomer();
                this.$store.dispatch(SET_BREADCRUMB, [
                    {title: "Khách hàng", route: "customer-list"},
                    {title: "Sửa khách hàng", icon: 'fas fa-users'}
                ]);
            }
        },
        computed: {
            ...mapState({
                errors: state => state.auth.errors
            }),
            ...mapGetters(['currentUser'])
        },
        methods: {
            saveCustomer() {
                this.check_loading = true;
                if (this.check_edit) {
                    this.$store.dispatch(CUSTOMER_UPDATE, this.customer).then((res) => {
                        this.check_loading = false;
                        if (!res.error) {
                            this.$router.push({name: "customer-list"}).then(() => {
                                this.$notify({
                                    title: 'Sửa khách hàng',
                                    message: 'Thành công',
                                    type: 'success'
                                });
                            })
                        } else {
                            this.$notify({
                                title: 'Sửa khách hàng',
                                message: res.message,
                                type: 'error'
                            });
                        }
                    }).catch((response) => {
                        if (response?.data?.message) {
                            this.$refs.form.setErrors(response.data.message);
                        }
                        this.$notify({
                            title: 'Sửa khách hàng',
                            message: 'Có lỗi xảy ra',
                            type: 'error'
                        });
                        this.check_loading = false;
                        return;
                    });
                } else {
                    this.$store.dispatch(CUSTOMER_CREATE, this.customer).then((res) => {
                        this.check_loading = false;
                        if (!res.error) {
                            this.$router.push({name: "customer-list"}).then(() => {
                                this.$notify({
                                    title: 'Thêm khách hàng',
                                    message: 'Thành công',
                                    type: 'success'
                                });
                            })
                        } else {
                            this.$notify({
                                title: 'Thêm khách hàng',
                                message: res.message,
                                type: 'error'
                            });
                        }
                    }).catch((response) => {
                        if (response?.data?.message) {
                            this.$refs.form.setErrors(response.data.message);
                        }
                        this.$notify({
                            title: 'Thêm khách hàng',
                            message: 'Có lỗi xảy ra',
                            type: 'error'
                        });
                        this.check_loading = false;
                        return;
                    });
                }
            },
            fetchCustomer() {
                this.$store.dispatch(CUSTOMER_FIND, this.$route.params.id).then((res) => {
                    this.customer = res.data;
                    this.customer.ngay_sinh = (this.customer.ngay_sinh == '0000-00-00' || !this.customer.ngay_sinh) ?  '':  this.customer.ngay_sinh
                    if (parseInt(res.data.level) < 3) {
                        this.checkDestroy = true;
                    } else {
                        this.checkDestroy = false;
                    }
                });
            },
            onSubmit() {

            },
            destroyCustomer() {
                this.$store.dispatch(CUSTOMER_DESTROY, {id: this.customer.id}).then((res) => {
                    this.check_loading_destroy = false;
                    if (!res.error) {
                        this.$router.push({name: "customer-list"}).then(() => {
                            this.$notify({
                                title: 'Hủy khách hàng',
                                message: 'Thành công',
                                type: 'success'
                            });
                        })
                    } else {
                        this.$notify({
                            title: 'Hủy khách hàng',
                            message: res.message,
                            type: 'error'
                        });
                    }
                }).catch((response) => {
                    this.$notify({
                        title: 'Hủy khách hàng',
                        message: 'Có lỗi xảy ra',
                        type: 'error'
                    });
                    this.check_loading_destroy = false;
                    return;
                });
            },
            handleViewContract(contract) {
                this.dialog.title = 'Chi tiết hợp đồng';
                this.dialogFormVisible = true;
                this.checkEdit = false;
                this.checkView = true;
                this.contract = contract;
            },
            closeDialog() {
                this.dialogFormVisible = false;
            },
            searchPhone: _.debounce(function () {
              this.checkReRegister();
            }, 300),
            checkReRegister(){
              this.$store.dispatch(CUSTOMER_FIND_RE_REGISTER, {phone:this.customer.phone}).then((res) => {
                    if(res.data) {
                      this.check_re_register = true;
                      this.check_clear = true;
                      this.customer.name = res.data.name;
                      this.customer.email = res.data.email;
                      this.customer.ngay_sinh = res.data.profile.birthday;
                      let check_complete_dept = res.data.check_contract.check_complete_dept;
                      let check_30days = res.data.check_contract.check_30days;
                      if( !check_complete_dept || !check_30days) {
                        this.dialogCompleteDebtOr30days = true;
                        this.isReRegister = {
                          completeDeblt : !check_complete_dept ? true : false,
                          contract30days : !check_30days ? true : false
                        }
                      }
                    } else {
                      if(this.check_re_register) {
                        this.customer.name = '';
                        this.customer.email = '';
                        this.customer.ngay_sinh = '';
                      }
                      this.check_re_register = false;
                    }
                });
            },
            clearReRegister(){
              this.check_re_register = false;
              this.customer.name = '';
              this.customer.email = '';
              this.customer.ngay_sinh = '';
            },
            closeAndClearInfor(){
              this.dialogCompleteDebtOr30days = false;
              this.clearReRegister();
              this.customer.phone = '';
            }
        }
    }
</script>
<style scoped>
  .mx-datepicker {
    width: 100% !important;
  }

  .card-header {
    color: #C9F7F5 !important;
  }
</style>
